import { GetEachProjectDetails } from "@/mixins/GetEachProjectDetails.js";
export const GetActionRights = {
  mixins: [GetEachProjectDetails],
  data() {
    return {
      actionItems: [],
      take_next_action: "",
      current_user_can_take_action: false,
    };
  },
  methods: {
    async checkActionRightsAccess() {
      await this.GetEachProjectDetailsMethod(this.StoreObj.project_id);
      let project_details = this.$store.getters.get_current_project_details;
      this.actionItems = [];
      this.take_next_action = "";
      this.current_user_can_take_action = false;
      switch (this.StoreObj.issue_status) {
        // case "UNACKNOWLEDGED":
        //   if (
        //     project_details.tester_lead_email_id == this.$store.getters.get_user_email ||
        //     project_details.issue_approvers != undefined
        //       ? project_details.issue_approvers.includes(this.$store.getters.get_user_email)
        //       : false
        //   ) {
        //     if (this.pendingTab == 0) {
        //       this.actionItems = [
        //         {
        //           text: "APPROVE",
        //           value: "APPROVE",
        //         },
        //         {
        //           text: "REJECT",
        //           value: "REJECT",
        //         },
        //       ];
        //     } else {
        //       this.actionItems = [
        //         {
        //           text: "WITHDRAW",
        //           value: "WITHDRAW",
        //         },
        //       ];
        //     }

        //     this.take_next_action = "ACKNOWLEDGED";
        //     this.current_user_can_take_action = true;
        //   }
        //   break;

            case "UNACKNOWLEDGED":
          if (project_details.tester_lead_email_id == this.$store.getters.get_user_email || project_details.issue_approvers.includes(this.$store.getters.get_user_email)) {
            if (this.pendingTab == 0) {
              this.actionItems = [
                {
                  text: "APPROVE",
                  value: "APPROVE",
                },
                {
                  text: "REJECT",
                  value: "REJECT",
                },
              ];
            } else {
              this.actionItems = [
                {
                  text: "WITHDRAW",
                  value: "WITHDRAW",
                },
              ];
            }

            this.take_next_action = "ACKNOWLEDGED";
            this.current_user_can_take_action = true;
          }
          break;
        case "ASSIGNED":
          if (this.StoreObj.is_reassigned == undefined || this.StoreObj.is_reassigned == false) {
            if (project_details.developer_lead_email_id == this.$store.getters.get_user_email) {
              this.actionItems = [
                {
                  text: "ASSIGN",
                  value: "ASSIGN",
                },
                {
                  text: "RESOLVED",
                  value: "FIXED",
                },
                {
                  text: "REJECT",
                  value: "REJECT",
                },
              ];
              this.take_next_action = "ASSIGNED";
              this.current_user_can_take_action = true;
            }
          } else if (this.StoreObj.is_reassigned == true) {
            if (project_details.developer_lead_email_id == this.$store.getters.get_user_email) {
              // Here We can Added Reassign as well so that the developer lead can once again reassign.
              this.actionItems = [
                {
                  text: "RESOLVED",
                  value: "FIXED",
                },
              ];
              this.take_next_action = "ASSIGNED";
              this.current_user_can_take_action = true;
            }
            if (
              project_details.developers_email != undefined
                ? project_details.developers_email.includes(this.$store.getters.get_user_email)
                : false
            ) {
              this.actionItems = [
                {
                  text: "RESOLVED",
                  value: "FIXED",
                },
                {
                  text: "REJECT",
                  value: "REJECT",
                },
              ];
              this.take_next_action = "ASSIGNED";
              this.current_user_can_take_action = true;
            }
          }
          break;
        // case "FIXED":
        //   if(project_details.issue_approvers != undefined){
        //   if (
        //     this.StoreObj.is_externally_reported_issue == undefined ||
        //     this.StoreObj.is_externally_reported_issue == false
        //   ) {
        //     if (
        //       this.$store.getters.get_user_email == this.StoreObj.issue_reported_by ||
        //       project_details.tester_lead_email_id == this.$store.getters.get_user_email
        //         ? project_details.issue_approvers.includes(this.$store.getters.get_user_email)
        //         : false
        //     ) {
        //       this.actionItems = [
        //         {
        //           text: "CLOSE",
        //           value: "CLOSE",
        //         },
        //         {
        //           text: "REOPEN",
        //           value: "REOPEN",
        //         },
        //       ];
        //       this.take_next_action = "CLOSED";
        //       this.current_user_can_take_action = true;
        //     }
        //   } else if (this.StoreObj.is_externally_reported_issue == true) {
        //     if (
        //       project_details.tester_lead_email_id == this.$store.getters.get_user_email ||
        //       project_details.issue_approvers != undefined
        //         ? project_details.issue_approvers.includes(this.$store.getters.get_user_email)
        //         : false
        //     ) {
        //       this.actionItems = [
        //         {
        //           text: "CLOSE",
        //           value: "CLOSE",
        //         },
        //         {
        //           text: "REOPEN",
        //           value: "REOPEN",
        //         },
        //       ];
        //       this.take_next_action = "CLOSED";
        //       this.current_user_can_take_action = true;
        //     }
        //   }
        // }

        case "FIXED":
          if (
            this.StoreObj.is_externally_reported_issue == undefined ||
            this.StoreObj.is_externally_reported_issue == false
          ) {
            if (
              this.$store.getters.get_user_email == this.StoreObj.issue_reported_by ||
              project_details.tester_lead_email_id == this.$store.getters.get_user_email ||
              project_details.issue_approvers.includes(this.$store.getters.get_user_email)
            ) {
              this.actionItems = [
                {
                  text: "CLOSE",
                  value: "CLOSE",
                },
                {
                  text: "REOPEN",
                  value: "REOPEN",
                },
              ];
              this.take_next_action = "CLOSED";
              this.current_user_can_take_action = true;
            }
          } else if (this.StoreObj.is_externally_reported_issue == true) {
            if (
              project_details.tester_lead_email_id == this.$store.getters.get_user_email ||
              project_details.issue_approvers.includes(this.$store.getters.get_user_email)
            ) {
              this.actionItems = [
                {
                  text: "CLOSE",
                  value: "CLOSE",
                },
                {
                  text: "REOPEN",
                  value: "REOPEN",
                },
              ];
              this.take_next_action = "CLOSED";
              this.current_user_can_take_action = true;
            }
          }
      }
      this.$forceUpdate();
    },
  },
};
