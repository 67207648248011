import { listCommentsOfIssue } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllIssueComments = {
  data() {
    return {
      noDataText: "",
      GetAllIssueCommentsList: [],
      overlay: false,
    };
  },
  methods: {
    async GetAllIssueCommentsMethod(issue_id,project_id) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listCommentsOfIssue, {
            input: {
              // project_id: this.$store.getters.get_current_project_details.project_id,
              // project_id: this.$store.getters.get_current_project_details.project_id,
              // project_id:"1cd695c2-5287-45b2-84f1-6064dc125610",
              project_id: !project_id ? this.$store.getters.get_current_project_details.project_id : project_id,
              // project_id:" 646de547-4484-40c3-86fe-280fe14e3839",
              issue_id,
            },
          })
        );
        this.GetAllIssueCommentsList = JSON.parse(result.data.ListCommentsOfIssue).Items.sort((a,b)=>{
          return new Date(a.commented_on).getTime()-new Date(b.commented_on).getTime()
          
        });
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        this.GetAllIssueCommentsList = [];
        this.noDataText = error.errors[0].message;
      }
    },
  },
};
