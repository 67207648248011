<template>
  <div>
    <v-dialog v-model="viewImageDialog" max-width="800px">
      <Overlay :overlay="overlay" />
      <v-card content-class :height="viewImageObject.attachment_icon == 'mdi-file-image' ? '' : '600px'">
        <v-carousel hide-delimiters height="625px">
          <template v-slot:prev="{ on, attrs }">
            <v-btn :disabled="current_idx == 0" icon v-bind="attrs" v-on="on" @click="checkAttachments('PREVIOUS')"> <v-icon>mdi-chevron-double-left </v-icon></v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn :disabled="current_idx == media_array.length - 1" icon v-bind="attrs" v-on="on" @click="checkAttachments('NEXT')"><v-icon>mdi-chevron-double-right </v-icon></v-btn>
          </template>

          <v-carousel-item>
            <v-card class="ma-2 py-4 elevation-10" align="center" justify="center" height="625">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-icon class="py-0 ml-0" dense @click="viewImageDialog = false" x-large label="zoom-in">mdi-close-circle</v-icon>
              </v-card-actions>

              <v-img class="mb-10" height="450px" width="700px" v-if="viewImageObject.attachment_icon == 'mdi-file-image'" contain :src="viewImageObject.attachment_url"> </v-img>
              <v-icon @click="zoominVdialog = true" x-large label="zoom-in">mdi-magnify-plus-outline</v-icon>
              <v-row>
                <video class="video" width="500px" height="270px" v-if="viewImageObject.attachment_icon == 'mdi-play-box-outline'" :src="viewImageObject.attachment_url" controls></video>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog v-model="dialogChangeIssuesStatus" persistent fullscreen>
      <v-overlay :value="overlayUploadAttachments">
        <v-progress-circular :rotate="180" :width="5" :value="loaderPercentage" color="white" size="120"> {{ count + 1 }} /{{ selectedFiles.length }} </v-progress-circular>
        <div class="PrimaryFontColorXSmall">Uploading Attachments...</div>
      </v-overlay>
      <v-card>
        <v-toolbar dark dense class="elevation-0" color="primary">
          {{ StoreObj.project_name }} ({{ $store.getters.get_current_project_details.project_display_id }})
          {{ StoreObj.action }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogChangeIssuesStatusEmit((Toggle = 1))"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-5 pb-0">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="6">
              <v-card class="pa-4 overflow-y-auto">
                <div class="font-weight-bold"><u>Issue Details :</u></div>
                <v-row no-gutters v-if="take_next_action == 'ACKNOWLEDGED' && $route.name == 'Issues'">
                  <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Issue ID </v-col>
                  <v-col cols="12" xs="12" sm="12" md="7" class="fontSize"> : {{ StoreObj.issue_id }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Reported By </v-col>
                  <v-col cols="12" xs="12" sm="12" md="7" class="fontSize"> : {{ StoreObj.issue_reported_by }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Ageing </v-col>
                  <v-col cols="12" xs="12" sm="12" md="7" class="fontSize"> : {{ checkTime(StoreObj.issue_reported_on) }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold">Description</v-col>
                  <v-col cols="12" xs="12" sm="12" md="7" class="fontSize"> : {{ StoreObj.issue_description }} </v-col>
                </v-row>

                <v-form ref="formAction" v-model="formAction">
                  <v-row no-gutters v-if="take_next_action == 'ACKNOWLEDGED' && pendingTab == 0">
                    <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Issue ID </v-col>
                    <v-col cols="12" xs="12" sm="12" md="7" class="fontSize"> : {{ StoreObj.issue_id }} </v-col>
                    <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Reported By </v-col>
                    <v-col cols="12" xs="12" sm="12" md="7" class="fontSize"> : {{ StoreObj.issue_reported_by }} </v-col>
                    <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Ageing </v-col>
                    <v-col cols="12" xs="12" sm="12" md="7" class="fontSize"> : {{ checkTime(StoreObj.issue_reported_on) }} </v-col>
                    <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold mt-4">Description</v-col>
                    <v-col cols="12" xs="12" sm="12" md="7" class="fontSize mt-4"
                      ><v-textarea
                        dense
                        outlined
                        rows="4"
                        class="FontSize field_height field_label_size"
                        label="Issue Description"
                        v-model="acknowledge_an_issue.issue_description"
                        :counter="1000"
                        :rules="[(v) => !!v || 'Required', (v) => (v && v.length <= 1000) || 'Description must be less than 1000 characters']"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Is It A Bug or Enhancement </v-col>
                    <v-col cols="12" xs="12" sm="12" md="7" class="fontSize">
                      <v-radio-group dense class="pa-0 ma-0" v-model="acknowledge_an_issue.issue_type" row :rules="[(v) => !!v || 'Required']">
                        <!-- :rules="action_status == 'REJECT' ? [(v) => !!v || 'Required'] : []" -->
                        <v-radio label="Bug" value="BUG"></v-radio>
                        <v-radio label="Enhancement" value="ENHANCEMENT"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Sub-System </v-col>
                    <v-col cols="12" xs="12" sm="12" md="7" class="fontSize">
                      <v-select dense outlined class="FontSize field_height field_label_size" label="Sub-System" :items="sub_systems" item-text="text" item-value="value" :rules="[(v) => !!v || 'Required']" v-model="acknowledge_an_issue.issue_reported_platform"></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="5" class="fontSize font-weight-bold"> Severity </v-col>
                    <v-col cols="12" xs="12" sm="12" md="7" class="fontSize">
                      <v-select dense outlined class="FontSize field_height field_label_size" label="Severity" v-model="acknowledge_an_issue.issue_severity" :items="issue_severityItems" item-text="text" item-value="value" :rules="[(v) => !!v || 'Required']"></v-select>
                    </v-col>
                  </v-row>
                </v-form>

                <v-row no-gutters v-if="pendingTab && pendingTab == 1">
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize font-weight-bold"> Issue ID </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize"> : {{ StoreObj.issue_id }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize font-weight-bold"> Is It A Bug or Enhancement </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize"> : {{ StoreObj.issue_type }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize font-weight-bold"> Reported By </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize"> : {{ StoreObj.issue_reported_by }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize font-weight-bold"> Ageing </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize"> : {{ checkTime(StoreObj.issue_reported_on) }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize font-weight-bold"> Sub-System </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize">
                    :
                    {{ StoreObj.issue_reported_platform != undefined ? StoreObj.issue_reported_platform.replace("_", " ") : "" }}
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize font-weight-bold">Title </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize">
                    <v-card class="elevation-0">
                      <v-card-text class="pa-0 fontSize"> : {{ StoreObj.issue_module_name }} </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize font-weight-bold mt-2"> Description</v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize">
                    <v-card class="mt-2 elevation-0">
                      <v-card-text class="pa-0 fontSize"> :Description</v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize font-weight-bold">Severity</v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" class="fontSize"> : {{ StoreObj.issue_severity.replace("_", " ") }}</v-col>
                </v-row>
                <v-row no-gutters v-if="take_next_action != 'ACKNOWLEDGED'">
                  <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold"> Issue ID </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="fontSize"> : {{ StoreObj.issue_id }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold"> Is It A Bug or Enhancement </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="fontSize"> : {{ StoreObj.issue_type }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold"> Reported By </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="fontSize"> : {{ StoreObj.issue_reported_by }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold"> Ageing </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="fontSize"> : {{ checkTime(StoreObj.issue_reported_on) }} </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold"> Sub-System </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="fontSize">
                    :
                    {{ StoreObj.issue_reported_platform != undefined ? StoreObj.issue_reported_platform.replace("_", " ") : "" }}
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold">Title </v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="fontSize">
                    <v-card class="elevation-0">
                      <v-card-text class="pa-0 fontSize"> : {{ StoreObj.issue_module_name }} </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold"> Description</v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="fontSize">
                    <v-card class="elevation-0">
                      <v-card-text class="pa-0 fontSize">
                        : {{ Formatted_Description }}
                        <div v-if="Formatted_Description.length >= 30">
                          <v-btn text x-small @click="ShowDescription = !ShowDescription">Read {{ ShowDescription ? "Less" : "More" }}</v-btn>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold">Severity</v-col>
                  <v-col cols="12" xs="12" sm="12" md="8" class="fontSize"> : {{ StoreObj.issue_severity.replace("_", " ") }}</v-col>
                </v-row>

                <v-form ref="formAction" v-model="formAction" v-if="$route.name == 'Approvals' || 'ProjectPage'">
                  <v-row v-if="current_user_can_take_action == true" class="mt-2">
                    <v-col v-if="pendingTab == 0 || (pendingTab == 1 && take_next_action == 'ACKNOWLEDGED')" cols="12" xs="12" sm="12" :md="pendingTab == 0 ? (take_next_action == 'ACKNOWLEDGED' ? '5' : '4') : '9'" class="fontSize font-weight-bold pb-0">Select Action</v-col>
                    <v-col v-if="pendingTab == 0 || (pendingTab == 1 && take_next_action == 'ACKNOWLEDGED')" cols="12" xs="12" sm="12" :md="pendingTab == 0 ? (take_next_action == 'ACKNOWLEDGED' ? '7' : '6') : '6'" class="fontSize pl-0 pb-0">
                      <v-select dense outlined class="FontSize field_height field_label_size" v-model="action_status" label="Select Action" :items="actionItems" :rules="[(v) => !!v || 'Required']"></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" class="fontSize font-weight-bold py-0" v-if="action_status == 'ASSIGN'">Select User</v-col>

                    <v-col cols="12" xs="12" sm="12" md="6" class="fontSize pl-0 py-0" v-if="action_status == 'ASSIGN'">
                      <v-select
                        dense
                        outlined
                        label="Select User"
                        class="FontSize field_height field_label_size"
                        v-model="assigned_to_email_id"
                        :items="GetAllUserDetailsList.filter((user) => user.user_email_id != $store.getters.get_user_email)"
                        item-text="user_email_id"
                        item-value="user_email_id"
                        :rules="[(v) => !!v || 'Required']"
                      ></v-select>
                    </v-col>
                    <v-col v-if="current_user_can_take_action == true && !pendingTab && pendingTab != 1" cols="12" xs="12" sm="12" :md="take_next_action == 'ACKNOWLEDGED' ? '5' : '4'" class="fontSize font-weight-bold py-0">
                      <div v-text="action_status != 'REJECT' ? 'Enter Comments (Optional)' : 'Enter Reasons For Rejection'"></div>
                    </v-col>
                    <v-col v-if="current_user_can_take_action == true && !pendingTab && pendingTab != 1" cols="12" xs="12" sm="12" :md="take_next_action == 'ACKNOWLEDGED' ? '7' : '6'" class="fontSize pl-0 py-0">
                      <v-textarea
                        dense
                        outlined
                        rows="1"
                        class="FontSize field_height field_label_size mt-2"
                        :label="action_status != 'REJECT' ? 'Enter Comments (Optional)' : 'Enter Reasons For Rejection'"
                        :rules="action_status == 'REJECT' ? [(v) => !!v || 'Required'] : []"
                        v-model="issue_comments"
                      ></v-textarea>
                    </v-col>
                    <v-col v-if="current_user_can_take_action == true && !pendingTab && pendingTab != 1" cols="12" xs="12" sm="12" :md="take_next_action == 'ACKNOWLEDGED' ? '5' : '4'" class="fontSize font-weight-bold py-0"> Upload Screenshots/Attachments </v-col>
                    <v-col v-if="current_user_can_take_action == true && !pendingTab && pendingTab != 1" cols="12" xs="12" sm="12" :md="take_next_action == 'ACKNOWLEDGED' ? '7' : '6'" class="fontSize pl-0 py-0">
                      <v-file-input dense v-model="selectedFiles" color="primary accent-4" counter multiple class="FontSize field_height field_label_size" label="Upload Attachments" prepend-icon="" :rules="[(v) => !!v || 'Required']" prepend-inner-icon="mdi-paperclip" outlined :show-size="1000">
                        <template v-slot:label>
                          <div class="mt-n1">Upload Screenshots/Attachments</div>
                        </template>
                        <template v-slot:prepend-inner>
                          <v-icon small class="pb-2">mdi-paperclip</v-icon>
                        </template>
                        <template v-slot:selection="{ index, text }">
                          <v-chip x-small class="ma-0" v-if="selectedFiles.length != 0 && index < 1" color="primary" dark label small>
                            <div class="FontSizeSmall">
                              {{ text.slice(0, 20) }}
                            </div>
                          </v-chip>
                          <span v-else-if="index === 1" class="grey--text text--darken-3"> +{{ selectedFiles.length - 1 }} File(s) </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-form>

                <!-- <v-form ref="formAction" v-model="formAction" v-if="$route.name == 'Approvals' || 'ProjectPage'">
                      <v-row  class="mt-2">
                        <v-col
                         
                          cols="12"
                          xs="12"
                          sm="12"
                          :md="pendingTab == 0 ? (take_next_action == 'ACKNOWLEDGED' ? '5' : '6') : '6'"
                          class="fontSize font-weight-bold pb-0"
                          >Select Action</v-col
                        >
                        <v-col
                        
                          cols="12"
                          xs="12"
                          sm="12"
                          :md="pendingTab == 0 ? (take_next_action == 'ACKNOWLEDGED' ? '7' : '6') : '6'"
                          class="fontSize pl-0 pb-0"
                        >
                          <v-select
                            dense
                            outlined
                            class="FontSize field_height field_label_size"
                            v-model="action_status"
                            label="Select Action"
                            :items="actionItems"
                            :rules="[(v) => !!v || 'Required']"
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                          xs="12"
                          sm="12"
                          md="6"
                          class="fontSize font-weight-bold py-0"
                          v-if="action_status == 'ASSIGN'"
                          >Select User</v-col
                        >
                        <v-col
                          cols="12"
                          xs="12"
                          sm="12"
                          md="6"
                          class="fontSize pl-0 py-0"
                          v-if="action_status == 'ASSIGN'"
                        >
                          <v-select
                            dense
                            outlined
                            label="Select User"
                            class="FontSize field_height field_label_size"
                            v-model="assigned_to_email_id"
                            :items="
                              GetAllUserDetailsList.filter(
                                (user) => user.user_email_id != $store.getters.get_user_email
                              )
                            "
                            item-text="user_email_id"
                            item-value="user_email_id"
                            :rules="[(v) => !!v || 'Required']"
                          ></v-select>
                        </v-col>
                        <v-col
                         
                          cols="12"
                          xs="12"
                          sm="12"
                          :md="take_next_action == 'ACKNOWLEDGED' ? '5' : '6'"
                          class="fontSize font-weight-bold py-0"
                        >
                          <div
                            v-text="
                              action_status != 'REJECT' ? 'Enter Comments (Optional)' : 'Enter Reasons For Rejection'
                            "
                          ></div>
                        </v-col>
                        <v-col
                          
                          cols="12"
                          xs="12"
                          sm="12"
                          :md="take_next_action == 'ACKNOWLEDGED' ? '7' : '6'"
                          class="fontSize pl-0 py-0"
                        >
                          <v-textarea
                            dense
                            outlined
                            rows="1"
                            class="FontSize field_height field_label_size mt-2"
                            :label="
                              action_status != 'REJECT' ? 'Enter Comments (Optional)' : 'Enter Reasons For Rejection'
                            "
                            :rules="action_status == 'REJECT' ? [(v) => !!v || 'Required'] : []"
                            v-model="issue_comments"
                          ></v-textarea>
                        </v-col>
                        <v-col
                       
                          cols="12"
                          xs="12"
                          sm="12"
                          :md="take_next_action == 'ACKNOWLEDGED' ? '5' : '6'"
                          class="fontSize font-weight-bold py-0"
                        >
                          Upload Screenshots/Attachments
                        </v-col>
                        <v-col
                       
                          cols="12"
                          xs="12"
                          sm="12"
                          :md="take_next_action == 'ACKNOWLEDGED' ? '7' : '6'"
                          class="fontSize pl-0 py-0" 
                        >
                          <v-file-input
                            dense
                            v-model="selectedFiles"
                            color="primary accent-4"
                            counter
                            multiple
                            class="FontSize field_height field_label_size"
                            label="Upload Attachments"
                            prepend-icon=""
                            :rules="[(v) => !!v || 'Required']"
                            prepend-inner-icon="mdi-paperclip"
                            outlined
                            :show-size="1000"
                          >
                            <template v-slot:label>
                              <div class="mt-n1">Upload Screenshots/Attachments</div>
                            </template>
                            <template v-slot:prepend-inner>
                              <v-icon small class="pb-2">mdi-paperclip</v-icon>
                            </template>
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                x-small
                                class="ma-0"
                                v-if="selectedFiles.length != 0 && index < 1"
                                color="primary"
                                dark
                                label
                                small
                              >
                                <div class="FontSizeSmall">
                                  {{ text.slice(0, 20) }}
                                </div>
                              </v-chip>
                              <span v-else-if="index === 1" class="grey--text text--darken-3">
                                +{{ selectedFiles.length - 1 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-row>
                    </v-form> -->
              </v-card>
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" v-if="GetAllIssueCommentsList.length != 0" class="pl-4">
              <v-toolbar dense class="elevation-1">
                <h3>BugTrail</h3>
              </v-toolbar>
              <v-card class="borderRadius mt-4 overflow-y-auto" :height="`${windowHeight - 30}`">
                <v-card class="borderRadius pa-2 ma-2 elevation-1" v-for="(index, i) in GetAllIssueCommentsList" :key="i">
                  <div class="fontSize"><Span class="font-weight-bold">Action :</Span><span v-text="formCommentType(index)"></span></div>
                  <div class="fontSize">
                    <Span class="font-weight-bold">Acted By :</Span><span>{{ index.commented_by }}</span>
                  </div>
                  <div class="fontSize">
                    <Span class="font-weight-bold">Comments :</Span><span>{{ index.comments.length == 0 ? "No Comments Added" : index.comments[0] }}</span>
                  </div>
                  <div class="fontSize font-weight-bold">Attachments :</div>
                  <v-row class="mt-1" v-if="index.issue_attachments != undefined && index.issue_attachments.length != 0">
                    <v-col v-for="(attachment, idx) in formatAttachmentsArray(index)" :key="idx" cols="12" xs="12" sm="12" md="2">
                      <v-card outlined height="70px" class="elevation-0" @click="viewImage(attachment, formatAttachmentsArray(index))" align="center">
                        <v-img height="65px" v-if="attachment.attachment_icon == 'mdi-file-image'" contain :src="attachment.attachment_url"></v-img>
                        <v-icon size="60px" color="primary" v-else-if="attachment.attachment_icon == 'mdi-play-box-outline'">mdi-play-box-outline</v-icon>
                        <v-icon size="60px" v-else v-text="attachment.attachment_icon" :color="attachment.attachment_color"></v-icon>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" xs="12" sm="12" md="12" align="center">
                      <div class="fontSize font-weight-bold">No Attachments Added</div>
                    </v-col>
                  </v-row>
                </v-card>
                <v-divider class="mx-2"></v-divider>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4" v-if="current_user_can_take_action == true && $route.name == 'Approvals'">
          <v-spacer></v-spacer>
          <v-btn v-if="!pendingTab || pendingTab == 0 || (pendingTab == 1 && take_next_action == 'ACKNOWLEDGED')" small class="borderRadius text-capitalize" color="primary" outlined @click="dialogChangeIssuesStatusEmit(1)"><v-icon small class="mr-1">mdi-close</v-icon>Cancel</v-btn>
          <v-btn v-if="!pendingTab || pendingTab == 0 || (pendingTab == 1 && take_next_action == 'ACKNOWLEDGED')" :disabled="!formAction" small class="borderRadius text-capitalize mr-2" color="primary" :loading="loading" @click="validateMethod()"
            ><v-icon small class="mr-1">mdi-check</v-icon>Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="zoominVdialog" persistent fullscreen>
      <v-card align="center">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-icon @click="zoominVdialog = false" x-large>mdi-close-circle</v-icon>
        </v-card-actions>
        <v-img height="600" v-if="viewImageObject.attachment_icon == 'mdi-file-image'" contain :src="viewImageObject.attachment_url"> </v-img>
        <video class="video ma-10" width="500px" height="270px" v-if="viewImageObject.attachment_icon == 'mdi-play-box-outline'" :src="viewImageObject.attachment_url" controls></video>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
var AWS = require("aws-sdk");
import Overlay from "@/components/Extras/Overlay.vue";
import FileIconGallery from "@/JsonFiles/FileIconGallery.json";
import { approveOrRejectIssueForTestLead, assignOrCloseOrReopenOrFixIssue, withdrawIssueForUser } from "@/graphql/mutations.js";
import { API, graphqlOperation } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { GetAllUsersDetails } from "@/mixins/GetAllUsersDetails.js";
import { GetActionRights } from "@/mixins/GetActionRights.js";
import { GetAllIssueComments } from "@/mixins/GetAllIssueComments.js";
export default {
  props: {
    dialogChangeIssuesStatus: Boolean,
    // zoominVdialog: Boolean,
    issue_status: String,
    pendingTab: Number,
    GetAllPendingIssuesList: Array,
    StoreObj: Object,
    ListIssuesOfUserAcrossAllProjectsArray: Array,
    current_pointer: Number,
    // GetAllIssueCommentsList:Array,
    callIntiateDataDialog: Boolean,
  },
  components: {
    Snackbar,
    Overlay,
  },
  mixins: [GetAllUsersDetails, GetActionRights, GetAllIssueComments],
  data: () => ({
    overlay: false,
    ShowDescription: false,
    Toggle: 0,
    viewImageDialog: false,
    action_status: "",
    zoominVdialog: false,
    assigned_to_email_id: "",
    issue_comments: "",
    action_status_items: ["APPROVE", "REJECT"],
    issue_status_ITEMS: ["CLOSE", "REOPEN"],
    loading: false,
    overlayUploadAttachments: false,
    SnackBarComponent: {},
    attachmentsHeader: [
      { text: "Sl No", value: "sl_no" },
      { text: "Name", value: "attachment_name" },
      { text: "Action", value: "Action" },
    ],
    action_status_items_ASSIGNED: ["FIXED", "ASSIGN"],
    FileIconGallery: [],
    viewImageObject: {},
    current_idx: -1,
    attachments_array: [],
    media_array: [],
    selectedFiles: [],
    count: 0,
    windowHeight: 0,
    loaderPercentage: 0,
    loaderPercentageDivider: 0,
    issue_attachments: [],
    acknowledge_an_issue: {
      issue_description: "",
      issue_type: "",
      issue_reported_platform: "",
      issue_reported_project_version: "",
      issue_severity: "",
    },
    sub_systems: [],
    issue_severityItems: [
      {
        text: "Not Specified",
        value: "NOT_SPECIFIED",
      },
      {
        text: "High",
        value: "HIGH",
      },
      {
        text: "Medium",
        value: "MEDIUM",
      },
      {
        text: "Low",
        value: "LOW",
      },
    ],
    formAction: false,
  }),
  watch: {
    current_pointer() {
      console.log("vishwa Console", this.StoreObj);
      this.GetAllIssueCommentsMethod(this.StoreObj.issue_id, this.StoreObj.project_id);
    },
    viewImageDialog(value) {
      if (value == false) {
        this.current_idx = -1;
        this.filteredArray = [];
      }
    },
    dialogChangeIssuesStatus(val) {
      if (val == true) {
        console.log("vishwa part2 Console", this.StoreObj);
        this.GetAllIssueCommentsMethod(this.StoreObj.issue_id, this.StoreObj.project_id);
      }
    },
  },
  computed: {
    Formatted_Description() {
      if (this.ShowDescription) {
        return this.StoreObj.issue_description;
      }
      return `${this.StoreObj.issue_description.slice(0, 30).trim()}.....`;
    },
  },
  async mounted() {
    this.getDialogData();
    this.GetAllIssueCommentsMethod(this.StoreObj.issue_id);
  },
  methods: {
    async getDialogData() {
      this.overlay = true;
      console.log(this.overlay);
      this.overlayUploadAttachments = false;
      if (this.StoreObj.issue_type == undefined) {
        this.acknowledge_an_issue.issue_type = "BUG";
      }
      await this.checkActionRightsAccess();
      this.GetAllUsersDetailsMethod("DEVELOPER");
      this.FileIconGallery = FileIconGallery;
      this.windowHeight = window.innerHeight - 170;
      this.GetAllIssueCommentsMethod(this.StoreObj.issue_id);
      if (this.take_next_action == "ACKNOWLEDGED") {
        this.sub_systems = [];
        this.acknowledge_an_issue.issue_description = this.StoreObj.issue_description;
        this.acknowledge_an_issue.issue_type = this.StoreObj.issue_type;
        this.sub_systems = await this.$store.getters.get_current_project_details.sub_systems.map((item) => {
          let Object = {};
          Object.text = item.project_platform;
          Object.value = item.project_platform;
          return Object;
        });
        this.sub_systems.push({
          text: "Not Specified",
          value: "NOT_SPECIFIED",
        });
        this.acknowledge_an_issue.issue_reported_platform = this.StoreObj.issue_reported_platform != undefined ? this.StoreObj.issue_reported_platform : "";
        this.acknowledge_an_issue.issue_reported_project_version = this.StoreObj.issue_reported_project_version ? this.StoreObj.issue_reported_project_version : "";
      }
      // this.callIntiateDataDialog = false;
      this.overlay = false;
    },
    formCommentType(index) {
      switch (index.comment_type) {
        case "UNACKNOWLEDGED":
          return `Issue Reported ${index.commented_by}`;
        case "ASSIGNED":
          if (index.is_reassigned == undefined) return `Acknowledged by ${index.commented_by} & Assigned to Dev Lead(${index.issue_assigned_to})`;
          else return `Reassigned to Developer ${index.issue_assigned_to}`;
        case "FIXED":
          return `Developer ${index.commented_by} Changed The Status To Resolved.`;
        case "CLOSED":
          return `Tester ${index.commented_by} Verified & Changed The Status To Closed`;
        case "REOPENED":
          return `Reopened By ${index.commented_by}`;
        case "REJECTED":
          return `Rejected By ${index.commented_by}`;
      }
    },
    async uploadImageToS3() {
      this.loading = true;
      this.overlayUploadAttachments = true;
      let s3Credentials = this.$store.getters.get_current_user_details.s3_details;
      this.loading = true;
      var self = this;
      var s3Bucket = new AWS.S3({
        region: s3Credentials.region,
        accessKeyId: s3Credentials.access_key,
        secretAccessKey: s3Credentials.secret_key,
      });
      var fileReader = new FileReader();
      fileReader.readAsDataURL(self.selectedFiles[self.count]);
      fileReader.onload = async function () {
        var base64FileContent = fileReader.result.split(",");
        var buf = Buffer.from(base64FileContent[1], "base64");
        var param = {
          Bucket: s3Credentials.bucket_name,
          Key: `BugTrakr/Issues/Attachments/${new Date().getTime()}_${self.selectedFiles[self.count].name}`,
          Body: buf,
          ContentType: self.selectedFiles.type,
          ACL: "public-read-write",
        };
        await s3Bucket.putObject(param, function (err, data) {
          if (err) {
            console.log(err);
            self.loading = false;
            self.overlayUploadAttachments = false;
          } else if (data) {
            if (self.count != self.selectedFiles.length - 1) {
              self.count += 1;
              self.issue_attachments.push(`https://${param.Bucket}.s3.ap-southeast-1.amazonaws.com/${encodeURI(param.Key)}`);
              self.uploadImageToS3();
              self.loaderPercentage += self.loaderPercentageDivider;
            } else {
              self.issue_attachments.push(`https://${param.Bucket}.s3.ap-southeast-1.amazonaws.com/${encodeURI(param.Key)}`);
              self.changeIssueStatusMethod(self.issue_attachments);
              self.loaderPercentage += 100;
            }
          }
        });
      };
    },
    checkAttachments(Action) {
      switch (Action) {
        case "PREVIOUS":
          this.current_idx -= 1;
          break;
        case "NEXT":
          this.current_idx += 1;
          break;
      }
      this.viewImageObject = this.media_array[this.current_idx];

      this.$forceUpdate();
    },
    checkTime(second) {
      var seconds = (new Date().getTime() - new Date(second).getTime()) / 1000;
      var d = Math.floor(seconds / (3600 * 24));
      var h = Math.floor((seconds % (3600 * 24)) / 3600);
      var m = Math.floor((seconds % 3600) / 60);
      var s = Math.floor(seconds % 60);

      var dDisplay = d > 0 ? d + (d == 1 ? " Day Ago" : " Days Ago") : "";
      var hDisplay = h > 0 ? h + (h == 1 ? " Hour Ago" : " Hours Ago") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " Minute Ago" : " Minutes Ago") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " Second Ago" : " Seconds Ago") : "";
      if (dDisplay != "") return dDisplay;
      else if (hDisplay != "") return hDisplay;
      else if (mDisplay != "") return mDisplay;
      else if (sDisplay) return sDisplay;
    },
    async viewImage(item, array) {
      this.viewImageObject = item;
      if (item.attachment_icon == "mdi-file-image" || item.attachment_icon == "mdi-play-box-outline") this.viewImageDialog = true;
      else window.open(item.attachment_url);
      this.media_array = array.filter((attachment) => attachment.attachment_icon == "mdi-file-image" || attachment.attachment_icon == "mdi-play-box-outline");
      this.current_idx = this.media_array.indexOf(item);
    },
    getColor(attachment_extension) {
      if (attachment_extension) {
        if (this.FileIconGallery.filter((icon) => attachment_extension == icon.extension).length != 0) return this.FileIconGallery.filter((icon) => attachment_extension == icon.extension)[0].color;
        else return "primary";
      } else return "primary";
    },
    getIcon(attachment_extension) {
      if (attachment_extension) {
        if (this.FileIconGallery.filter((icon) => attachment_extension == icon.extension).length != 0) return this.FileIconGallery.filter((icon) => attachment_extension == icon.extension)[0].icon;
        else return "mdi-file";
      } else return "mdi-file";
    },
    validateMethod() {
      if (this.$refs.formAction.validate()) {
        if (this.selectedFiles.length != 0) {
          this.count = 0;
          this.loaderPercentage = 0;
          this.loaderPercentageDivider = 100 / this.selectedFiles.length;
          this.uploadImageToS3();
        } else {
          this.changeIssueStatusMethod();
        }
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Please Select An Action",
        };
      }
    },
    downloadItem(item) {
      window.open(item.attachment_url);
    },
    formatAttachmentsArray(index) {
      return index.issue_attachments.map((item, idx) => {
        let object = {
          sl_no: idx + 1,
          attachment_url: item,
          attachment_name: decodeURI(item).split("_")[item.split("_").length - 1].toLowerCase(),
          attachment_extension: decodeURI(item).split(".")[item.split(".").length - 1].toLowerCase(),
          attachment_icon: this.getIcon(decodeURI(item).split(".")[item.split(".").length - 1].toLowerCase()),
          attachment_color: this.getColor(decodeURI(item).split(".")[item.split(".").length - 1].toLowerCase()),
        };
        return object;
      });
    },
    async changeIssueStatusMethod(issue_attachments) {
      console.log("Check action_status", this.action_status);
      var mutationname = this.action_status != "WITHDRAW" ? (this.take_next_action == "ACKNOWLEDGED" ? approveOrRejectIssueForTestLead : assignOrCloseOrReopenOrFixIssue) : withdrawIssueForUser;
      var mutationresult = this.action_status != "WITHDRAW" ? (this.take_next_action == "ACKNOWLEDGED" ? "ApproveOrRejectIssueForTestLead" : "AssignOrCloseOrReopenOrFixIssue") : "WithdrawIssueForUser";
      var inputParams = {
        user_email_id: this.$store.getters.get_user_email,
        project_id: this.action_status != "WITHDRAW" ? (this.$store.getters.get_current_project_details.project_id != undefined ? this.$store.getters.get_current_project_details.project_id : undefined) : undefined,
        issue_id: this.StoreObj.issue_id,
        action_status: this.action_status != "WITHDRAW" ? (this.take_next_action == "ACKNOWLEDGED" ? this.action_status : undefined) : undefined,
        assigned_to_email_id: this.take_next_action != "ACKNOWLEDGED" && this.action_status == "ASSIGN" ? this.assigned_to_email_id : undefined,
        issue_status: this.action_status != "WITHDRAW" ? (this.take_next_action != "ACKNOWLEDGED" ? this.action_status : undefined) : undefined,
        issue_comments: this.action_status != "WITHDRAW" ? (this.issue_comments && this.issue_comments != "" ? this.issue_comments : undefined) : undefined,
        [this.take_next_action == "ACKNOWLEDGED" ? "issue_attachments" : "issue_attachments"]: this.action_status != "WITHDRAW" ? (issue_attachments ? issue_attachments : undefined) : undefined,
        issue_module_name: this.action_status != "WITHDRAW" ? (this.take_next_action == "ACKNOWLEDGED" ? this.acknowledge_an_issue.issue_description.slice(0, 100) : undefined) : undefined,
        issue_description: this.action_status != "WITHDRAW" ? (this.take_next_action == "ACKNOWLEDGED" ? this.acknowledge_an_issue.issue_description : undefined) : undefined,
        issue_reported_platform: this.action_status != "WITHDRAW" ? (this.take_next_action == "ACKNOWLEDGED" ? this.acknowledge_an_issue.issue_reported_platform : undefined) : undefined,
        issue_reported_project_version:
          this.action_status != "WITHDRAW"
            ? this.take_next_action == "ACKNOWLEDGED"
              ? this.acknowledge_an_issue.issue_reported_project_version && this.acknowledge_an_issue.issue_reported_project_version != ""
                ? this.acknowledge_an_issue.issue_reported_project_version
                : undefined
              : undefined
            : undefined,
        issue_type: this.action_status != "WITHDRAW" ? (this.take_next_action == "ACKNOWLEDGED" ? this.acknowledge_an_issue.issue_type : undefined) : undefined,
        issue_severity: this.action_status != "WITHDRAW" ? (this.take_next_action == "ACKNOWLEDGED" ? this.acknowledge_an_issue.issue_severity : undefined) : undefined,
      };
      try {
        this.loading = true;
        let result = await API.graphql(
          graphqlOperation(mutationname, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data[mutationresult]);
        if (ResultObject.status == "success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.message,
          };
          this.dialogChangeIssuesStatusEmit((this.Toggle = 2));
        }
        this.loading = false;
        this.overlayUploadAttachments = false;
      } catch (error) {
        console.log(error);
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.loading = false;
        this.overlayUploadAttachments = false;
      }
    },
    dialogChangeIssuesStatusEmit(Toggle) {
      this.selectedFiles = [];
      this.overlayUploadAttachments = false;
      this.$forceUpdate();
      console.log("Toggle", Toggle);
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
<style scoped>
.container {
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
}
.video {
  width: 100%;
  height: 300px;
}
.c-video {
  width: 100%;
  height: 300px;
  max-width: 650px;
}
</style>
