import { listIssues } from "@/graphql/queries.js";
import { API, graphqlOperation } from "aws-amplify";
export const GetAllIssuesDetails = {
  data() {
    return {
      noDataText: "",
      GetAllIssuesDetailsList: [],
      overlay: false,
    };
  },
  methods: {
    async GetAllIssuesDetailsMethod(issue_status) {
      try {
        this.overlay = true;
        let result = await API.graphql(
          graphqlOperation(listIssues, {
            input: {
              project_id: this.$store.getters.get_current_project_details.project_id,
              issue_status,
            },
          })
        );
        this.GetAllIssuesDetailsList = JSON.parse(result.data.ListIssues).Items;
        this.filterItems();
        if (this.GetAllIssuesDetailsList.length == 0) {
          this.noDataText = `No ${issue_status} Issues Found `;
        }
        this.overlay = false;
        this.$store.commit("SET_REFRESH", false);
        console.log("Project detailes",this.GetAllIssuesDetailsList)
      } catch (error) {
        this.overlay = false;
        this.GetAllIssuesDetailsList = [];
        this.noDataText = error.errors[0].message;
        this.$store.commit("SET_REFRESH", false);
      }
    },
  },
};
